<script setup>
import RequestDemo from "@/Components/RequestDemo.vue";
import ChatComponent from "@/Components/ChatComponent.vue";
import { trans } from "laravel-vue-i18n";
import AnimatedComponent from "@/Components/AnimatedComponent.vue";

const words = [trans('Artificial Intelligence'),trans('medical expertise '), trans('modern technology '),trans('safety and privacy '),trans('respect and empathy ')]


</script>

<template>

<div class="hidden lg:block ">
    <div class="gradient-border-mask w-full mt-12 px-20 py-[148px] h-4/5 rounded-[48px] before:rounded-[48px] ">
      <div class="flex items-center justify-between w-full gap-x-6">
        <animated-component animation-type="fade-left">
          <div class="flex flex-col flex-[7_7_0%]" >
            <p class="text-main-hero-white text-3xl xl:text-[40px]">{{ $t('Patient visits turbocharged by ') }}</p>

              <span class="font-semibold text-3xl xl:text-5xl text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] xl:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight))] overflow-hidden">
      <ul class="block animate-text-slide-5 text-left leading-tight [&_li]:block">

          <li class="gradient-text">{{ $t(words[0]) }}</li>
          <li class="gradient-text">{{  $t(words[1]) }}</li>
          <li class="gradient-text">{{  $t(words[2]) }}</li>
          <li class="gradient-text">{{  $t(words[3]) }}</li>
          <li class="gradient-text">{{  $t(words[4]) }}</li>
          <li class="gradient-text" aria-hidden="true">{{  $t(words[0]) }}</li>

      </ul>
  </span>
            <p class="text-base text-main-white w-4/5 mt-3 mb-4">{{ $t('A cutting-edge AI tool that assists doctors and empowers patients, enhancing the quality of medical visits.') }}</p>
            <RequestDemo :title="$t('Request Demo')" :appearance="'primary-button'"/>
          </div>
        </animated-component>
        <div class="flex-[5_5_0%] flex justify-end " >
          <ChatComponent/>
        </div>
      </div>


    </div>
</div>
  <!--        mobile view-->
  <div class="flex lg:hidden flex-col mt-14 items-center px-5" >
    <p class="text-main-hero-white text-[22px]">{{ $t('Patient visits turbocharged by ') }}</p>
    <span class="font-semibold text-3xl text-indigo-500 inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] overflow-hidden">
    <ul class="block animate-text-slide-5 text-left leading-tight [&_li]:block">
        <li class="gradient-text">{{ $t(words[0]) }}</li>
        <li class="gradient-text">{{ $t(words[1]) }}</li>
        <li class="gradient-text">{{ $t(words[2]) }}</li>
        <li class="gradient-text">{{ $t(words[3]) }}</li>
        <li class="gradient-text">{{ $t(words[4]) }}</li>
        <li class="gradient-text" aria-hidden="true">{{ $t(words[0]) }}</li>
    </ul>
</span>
    <p class="text-sm text-main-white mt-6 mb-8">{{ $t('A cutting-edge AI tool that assists doctors and empowers patients, enhancing the quality of medical visits.') }}</p>
    <ChatComponent class="pb-11"/>
    <RequestDemo :title="$t('Request Demo')" :appearance="'primary-button'"/>
  </div>
</template>
