<template>
     <transition name="fade">
       <div class="!mt-0">
         <!--         mobile view-->
         <div v-if="showModal" class="flex xl:hidden gradient-bg fixed w-screen h-screen inset-0 z-50 flex-col py-8 px-5">
           <div class="relative flex flex-col">
             <div @click="showModal = false" class="absolute flex">
               <img :src="chevronLeftIcon" alt="back-icon"/>
               <span class="text-sm text-white ml-2">Go back</span>
             </div>
             <img class='mx-auto h-6' :src="logo" alt="logo"/>
             <p class="text-left text-white text-lg mt-16">{{ $t('If you want to contact the team behind previsit.ai, please fill in the form below') }}</p>
             <form @submit.prevent="recaptcha" class="mt-6 flex flex-col">
               <div class="text-left">
                 <label for="email" class="block relative text-white">{{ $t('Email address') }}
                   <img class='absolute left-4 top-[46px]' :src="mailIcon" alt='mail icon'/>
                   <input type="email" id="email" v-model="form.email" placeholder="name@gmail.com" required class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 pl-12">
                 </label>
               </div>
               <div class="text-left mt-4">
                 <label for="subject" class="block relative text-white">{{ $t('Subject:') }}
                   <img class='absolute left-4 top-[46px]' :src="mailIcon" alt='mail icon'/>
                   <input type="text" id="subject" v-model="form.subject" :placeholder="$t('Subject')" required class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 pl-12">
                 </label>
               </div>
               <div class="text-left mt-4" >
                 <label for="message" class="block relative text-white">{{ $t('Message') }}
                   <textarea type="text" id="message" v-model="form.message" placeholder="" required class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 "></textarea>
                 </label>
               </div>
               <PrimaryButton class="w-full px-10 py-3 bg-main-blue mt-6 ml-auto items-center justify-center rounded-xl">
                 <span class="text-white text-base">{{ $t('Drop us a line') }}</span>
               </PrimaryButton>
             </form>
           </div>
         </div>
         <!--         desktop view-->
         <div v-if="showModal" class="hidden fixed inset-0 xl:flex items-center justify-center z-50 ">
           <div class="fixed inset-0 bg-black opacity-50" @click="showModal = false"></div>
           <div class="flex flex-col bg-[#1A1A1A] p-6 rounded-md before:rounded-md shadow-lg shadow-white/10 relative w-full max-w-md mx-auto gradient-border-demo">
             <button @click="showModal = false"><img class='ml-auto' :src="closeIcon" alt='close'/></button>
             <p class="text-left text-white text-lg mt-1">{{ $t('If you want to contact the team behind previsit.ai, please fill in the form below') }}</p>
             <form @submit.prevent="recaptcha" class="mt-6 flex flex-col">
               <div class="text-left">
                 <label for="email" class="block relative text-white">{{ $t('Email address') }}
                   <img class='absolute left-4 top-[46px]' :src="mailIcon" alt='mail icon'/>
                   <input type="email" id="email" v-model="form.email" placeholder="name@gmail.com" required class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 pl-12">
                 </label>
               </div>
               <div class="text-left mt-4">
                 <label for="subject" class="block relative text-white">{{ $t('Subject:') }}
                   <img class='absolute left-4 top-[46px]' :src="mailIcon" alt='mail icon'/>
                   <input type="text" id="subject" v-model="form.subject" :placeholder="$t('Subject')" required class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 pl-12">
                 </label>
               </div>
               <div class="text-left mt-4" >
                 <label for="message" class="block relative text-white">{{ $t('Message') }}
                   <textarea type="text" id="message" v-model="form.message" placeholder="" required class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 "></textarea>
                 </label>
               </div>
               <PrimaryButton class="px-10 py-4 bg-main-blue mt-6 text-white ml-auto text-base">
                 {{ $t('Drop us a line') }}
               </PrimaryButton>
             </form>

           </div>
         </div>
       </div>



     </transition>

     <div class="flex justify-right md:justify-center md:!mt-0" id="try-it-out">
        <button v-if="appearance === 'button'" @click="showModal=true" class="text-right bg-main-blue text-white px-4 py-2 rounded-[36px]">
            {{ $t(title) }}
        </button>
        <button v-else @click="showModal=true" class="md:inline-flex md:hidden md:items-center text-white text-lg md:px-3 md:py-2 md:border md:border-transparent md:text-sm md:leading-4 md:font-medium md:rounded-md md:text-gray-500 md:bg-white md:hover:text-gray-700 md:focus:outline-none md:transition md:ease-in-out md:duration-150">
            {{ $t(title) }}
        </button>
        <!-- {{  $t('Contact') }}-->
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useForm,usePage } from "@inertiajs/vue3";
import { useReCaptcha } from "vue-recaptcha-v3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import chevronLeftIcon from "../../img/icons/chevronLeftIcon.svg";
import logo from "../../img/logo-name.svg";
import mailIcon from "../../img/icons/mailIcon.svg";
import closeIcon from "../../img/icons/closeIcon.svg";
function openModal() {
    
    showModal.value = true;
  }
const showModal = ref(false);
const form = useForm({
    email: '',
    number: '',
    subject: '',
    message: '',
    captcha_token: null
});
const page = usePage();
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const recaptcha = async () => {
    // console.log("recaptcha");
  await recaptchaLoaded();
  form.captcha_token = await executeRecaptcha("login");
  submit();
};

const submit = () => {
    console.log("submitting");
  form.post(route("contact"), {
    onSuccess: (data) => {
        form.email = '';
        form.number = '';
        form.subject = '';
        form.message = '';
        form.captcha_token = null;
        showModal.value = false;
        console.log("success", data);
    },
    onFinish: () => form.reset("password", "password_confirmation"),
  });
};

const submitForm = () => {
    // Here you can send a POST request to your server with the form data
    // You can use the fetch API or a library like axios
};

const props = defineProps({
  title: {
    type: String,
    default: 'Contact'
  },
    appearance: {
        type: String,
        default: 'default'
    }
});

defineExpose({openModal})
</script>

<style scoped>
.fade-enter-active {
    transition: opacity 0.5s ease;  
}
.fade-leave-active {
  transition: opacity 1.0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
