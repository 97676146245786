<script setup>
import { ref, onMounted,onUnmounted,nextTick } from 'vue';
import { Head, Link, usePage} from '@inertiajs/vue3';
// import Dropdown from '@/Components/Dropdown.vue';
// import DropdownLink from '@/Components/DropdownLink.vue';
// import DropdownLanguage from '@/Components/DropdownLanguage.vue';
// import ContactForm from "@/Components/ContactForm.vue";
// import RequestDemo from "@/Components/RequestDemo.vue";
import Navigation from "@/Components/Navigation.vue"
import VideoSection from "@/Components/VideoSection.vue"
import HeroSection from "@/Components/HeroSection.vue"
import OurClients from "@/Components/OurClients.vue"
import FeaturesSection from "@/Components/FeaturesSection.vue"
import OurOfferSection from "@/Components/OurOfferSection.vue"
import HowItWorksSection from "@/Components/HowItWorksSection.vue"
import PricingSection from "@/Components/PricingSection.vue"
import AboutUsSection from "@/Components/AboutUsSection.vue"
import Footer from "@/Components/Footer.vue"


// import step1Img from '../../img/step1.jpg';
// import step2Img from '../../img/step2.jpg';
// import step3Img from '../../img/step3.jpg';
// import logo from '../../img/logo-v2.png';


import { computed } from 'vue';

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
    // laravelVersion: {
    //     type: String,
    //     required: true,
    // },
    // phpVersion: {
    //     type: String,
    //     required: true,
    // },
 
});

const page = usePage();
const locale_language = computed(() => {
    if (page.props.locale && page.props.site_languages) {
         return page.props.site_languages[page.props.locale].name;
    } else {
        return "Language";
    }


});

const scrollToTeaser = () => {
    nextTick(() => {
        const element = document.getElementById('teaser');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    });
};







</script>

<template>
    <Head title="Home" />
  <div class="container mx-auto px-4 md:px-10 lg:px-12 pb-60 md:pb-0 overflow-x-hidden" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
<Navigation/>
    <HeroSection/>

    <VideoSection/>

    <OurClients/>

    
    <FeaturesSection/>
    <OurOfferSection/>
    <HowItWorksSection/>
    
    <AboutUsSection/>
    <PricingSection/>
    </div>
    <Footer />
</template>

<!--    <div class="absolute top-0 inset-x-0 px-4 mt-5 flex justify-between md:justify-end md:px-10">-->
<!--    &lt;!&ndash; Mobile Layout: Visible on small screens &ndash;&gt;-->
<!--    <div class="md:hidden">-->
<!--        <DropdownLanguage class="text-left" />-->
<!--    </div>-->
<!--    <div class="md:hidden">-->
<!--        <a href="/login" class="bg-gray-200 text-black py-2 px-4 rounded">{{ $t('Log in') }}</a>-->
<!--    </div>-->

<!--    &lt;!&ndash; Desktop Layout: Visible on medium screens and up &ndash;&gt;-->
<!--    <div class="hidden md:flex md:flex-row-reverse md:space-x-4 md:space-x-reverse dropdown-container">-->
<!--        <a href="/login" class="bg-gray-200 text-black py-2 px-4 rounded">{{ $t('Log in') }}</a>-->
<!--        <DropdownLanguage />-->
<!--        <ContactForm ref="contactRef" />-->
<!--        <RequestDemo ref="requestDemoRef" />-->
<!--    </div>-->
<!--</div>-->
<!--    <div id="container" style="height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center;">-->

<!--        <div>-->
<!--            <h1 class="flex items-center"><img class="mr-2 app-logo" :src="logo"/>previsit.<span class="ai-color">ai</span></h1> -->
<!--        </div>-->
<!--        -->
<!--  -->
<!--        &lt;!&ndash; <div class="text-center">-->
<!--            <small class="text-gray-600">{{ $t('Patient visits turbocharged by ') }} -->
<!--                -->
<!--                <div class="text-center relative overflow-hidden" style="height: 24px;">-->
<!--    <transition name="slide-fade" mode="out-in">-->
<!--        <span key="rotatingKeyword" :class="rotatingKeyword === 'Artificial Intelligence' ? 'text-gray-800' : 'text-gray-600'" class="absolute">-->
<!--            {{ $t(rotatingKeyword) }}-->
<!--        </span>-->
<!--    </transition>-->
<!--</div>-->



<!--            </small>-->
<!--        </div> &ndash;&gt;-->

<!--        <div class="w-full max-w-6xl mx-auto px-4 mt-6">-->
<!--            <div class="text-center">-->


<!--<div class="inline text-gray-600 text-3xl md:text-4xl [text-wrap:balance] bg-clip-text">{{ $t('Patient visits turbocharged by ') }}-->
<!--<span class="ai-color inline-flex flex-col h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] md:h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight))] overflow-hidden">-->
<!--    <ul class="lg:inline md:block md:text-center animate-text-slide-5 lg:text-left leading-tight">-->
<!--        <li>{{ $t('Artificial Intelligence')}}</li>-->
<!--        <li>{{ $t('medical expertise ')}}</li>-->
<!--        <li>{{ $t('modern technology ')}}</li>-->
<!--        <li>{{ $t('safety and privacy ')}}</li>-->
<!--        <li>{{ $t('respect and empathy ')}}</li>-->
<!--        <li aria-hidden="true">{{ $t('Artificial Intelligence')}}</li>-->
<!--    </ul>-->
<!--</span></div>-->
<!--                -->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="mt-4 flex justify-center" id="try-it-out">-->
<!--    <button @click="scrollToTeaser" class="bg-blue-600 text-white py-2 px-4 rounded text-center text-white">{{ $t('How it works?') }}</button>-->
<!--</div>-->
<!--     -->
<!--        -->
<!--    </div>-->

<!--       -->
<!--        -->
<!--          </div>-->
<!--          <div  id="teaser" class="mt-4">-->

<!--                <div class="flex justify-center">-->

<!--                    -->
<!--                        <h1 class="text-center text-4xl">{{$t('Previsit.ai is very simple')}}</h1>-->
<!--                    </div>-->
<!--          -->
<!--                <div>-->
<!--                <div class="infographic-container pt-20 h-auto" >-->
<!--                <div class="infographic">-->
<!--                    <img :src="step1Img" alt="Step 1" class="animated-image">-->
<!--                    <div class="subtitle mt-2 bg-blue-50 rounded-lg p-2"><span class="text-gray-500">{{ $t('Step 1') }}</span>: {{ $t('Doctor invites the patient to fill in previsit via his software or a website. If doctor uses an online agenda this invitation will be sent automatically.') }}</div>-->
<!--                </div>-->
<!--                <div class="infographic text-justify">-->
<!--                    <img :src="step2Img" alt="Step 2" class="animated-image">-->
<!--                    <div class="subtitle mt-2  bg-blue-50 rounded-lg p-2"><span class="text-gray-500">{{ $t('Step 2') }}</span> {{ $t('Patient receives a link and holds a conversation with special AI assistant. The polite assistant makes sure that the patient provides him with the necessary information.') }}</div>-->
<!--                </div>-->
<!--            -->
<!--            <div class="infographic">-->
<!--                <img :src="step3Img" alt="Step 3" class="animated-image">-->
<!--                <div class="subtitle mt-2  bg-blue-50 rounded-lg p-2">-->
<!--                    <span class="text-gray-500">{{ $t('Step 3') }}</span>-->
<!--                    {{ $t('Doctor receives a medical grade previsit anamnesis which can be included in his documentation or used as a basis for the consultations. ') }} {{ $t('No patient personal data is processed.') }}</div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="mt-4 flex justify-center" id="try-it-out">-->

<!--            <RequestDemo :title="$t('Request Demo')" :appearance="'button'"/>-->


<!--        </div>-->

<!--        <div class="mt-10 flex justify-center md:hidden" id="try-it-out">-->
<!--            <ContactForm :title="$t('Contact us')" :appearance="'button'"/>-->
<!--        </div>-->
<!--        </div>-->


<!--        <div id="software-developers" class="mt-64">-->
<!--            <div class="flex justify-center">-->
<!--                <h1 class="text-center text-4xl">{{$t('Previsit.ai can be easily integrated with software')}}</h1>-->
<!--            </div>-->
<!--            <div class="flex justify-center">-->
<!--                -->
<!--                <div  class="subtitle mt-2 w-2/3  bg-blue-50 rounded-lg p-2" role="alert">-->
<!--                    {{ $t('We provide a simple API that allows to integrate your medical software in a matter of hours. If you are a software developer, you can start using previsit.ai right now and integrate it in your workflow. ') }}-->
<!--                    -->
<!--                </div>-->
<!--          -->
<!--                -->
<!--            </div>-->
<!--            <div class="flex justify-center mt-8">-->
<!--                    <ContactForm :title="$t('Contact us for API access')" :appearance="'button'"/>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="mt-10 mr-4 text-center md:text-right text-gray-700">-->
<!--            <p>© 2024 Nedo Michał Nedoszytko</p>-->
<!--        </div>-->
<!--          </div> -->
<!--    -->
<!--    -->

<!--          -->


<!--    </div>-->
    


<style>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.slide-fade-enter-to, .slide-fade-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.subtitle {
    font-size: 1rem;
    text-align: justify;
}
.infographic-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    /* height: 100vh; */
}
.infographic {
    display: flex;
    text-align: justify;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem;
    max-height: 780px;
    max-width: 300px;
}
.welcome h1 {
    font-size: 4rem;
}
.welcome small {
    font-size: 1.5rem;
}
.bg-dots-darker {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(0,0,0,0.07)'/%3E%3C/svg%3E");
}
@media (prefers-color-scheme: dark) {
    .dark\:bg-dots-lighter {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(255,255,255,0.07)'/%3E%3C/svg%3E");
    }
}

.app-logo {
    width: 62px;
    margin-top: 13px;
    /* height: 50px; */
}

.ai-color {
    color: #558cfa;
}


</style>



