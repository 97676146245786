<script setup>
import includedIcon from '../../img/icons/includedIcon.svg'
import notIncludedIcon from '../../img/icons/notIncludedIcon.svg'
import {ref,onMounted} from "vue";
import {trans} from 'laravel-vue-i18n';
import AnimatedComponent from "@/Components/AnimatedComponent.vue";
import { usePage } from '@inertiajs/vue3';
const page = usePage()

let pricing = page.props.pricing || null;


const getPricingForPlan = (plan) => {
  // console.log("getting pricing for plan", plan, pricing);
  if (pricing === null) {
    return null;
  } else {
    const foundPlan = pricing.find(element => element.name === plan);
    // console.log("Found plan:", foundPlan);
    return foundPlan;
  }
}

function getPricingElementForPlan(plan, element) {
  let pricingPlan = getPricingForPlan(plan);
  // console.log("Pricing plan details:", pricingPlan);
  if (typeof(pricingPlan) === 'undefined' || pricingPlan === null || pricingPlan[element] === undefined) {
    // console.log(`Element '${element}' not found for plan '${plan}'`);
    return null;
  } else {
    return pricingPlan[element];
  }
}
function getCurrencyForPlan(plan) {
  return getPricingElementForPlan(plan,'currency');
}
function getMonthlyPriceForPlan(plan) {
  return getPricingElementForPlan(plan,'monthly_price');
}
function getYearlyPriceForPlan(plan) {
  return getPricingElementForPlan(plan,'yearly_price');
}

function getYearlyMonthlyPriceForPlan(plan) {
  return getPricingElementForPlan(plan,'yearly_monthly_price');
}

function getYearlymonthlyPriceForPlanFormatted(plan) {
  const price = getYearlyMonthlyPriceForPlan(plan);
  const currency = getCurrencyForPlan(plan);
  let priceFormatted = formatPrice(price,currency);
  return priceFormatted = priceFormatted + trans('/mo.');

}

function formatPrice(price,currency) {
  //if currency is dollar then add $ sign before the price
  if (currency === 'dollar') {
    return '$' + price;
  } else {
    if (currency == 'PLN') {
      return price + ' zł';
    }
    if (currency == 'EUR') {
      return price + ' €';
    }
    return price + currency;
  
}
}




const pricingPlans = [{
  title: 'Free',
  subTitle: trans('Pay as you go'),
  numberOfInvitations: 10,
  inviteOption:true,
  discountOption:false,
  tokenOption: false,
  earlyAccess: false,
  monthlyPrice: formatPrice(0,getCurrencyForPlan('Free')),
  annualPrice: formatPrice(0,getCurrencyForPlan('Free')),
  
  buttonText: trans('Try for free')
},
  {
    title: 'Basic',
    subTitle: trans('Basic option'),
    numberOfInvitations: 50,
    inviteOption:true,
    discountOption:'10%',
    tokenOption: trans('Accumulate over month'),
    earlyAccess: false,
    monthlyPrice: formatPrice(getMonthlyPriceForPlan('Basic'),getCurrencyForPlan('Basic')),
    annualPrice: formatPrice(getYearlyMonthlyPriceForPlan('Basic'),getCurrencyForPlan('Basic')),
    buttonText: trans('Subscribe'),
    mobileButtonMonth: formatPrice(getMonthlyPriceForPlan('Basic'),getCurrencyForPlan('Basic')),
    mobileButtonYear: formatPrice(getYearlyPriceForPlan('Basic'),getCurrencyForPlan('Basic'))
  },
  {
    title: 'Premium',
    subTitle: trans('Most popular one'),
    numberOfInvitations: 100,
    inviteOption:true,
    discountOption:'20%',
    tokenOption: trans('Accumulate over month'),
    earlyAccess: false,
    monthlyPrice: formatPrice(getMonthlyPriceForPlan('Premium'),getCurrencyForPlan('Premium')),
    annualPrice: formatPrice(getYearlyMonthlyPriceForPlan('Premium'),getCurrencyForPlan('Premium')),
    buttonText: trans('Subscribe'),
    fillButton:true,
    mobileButtonMonth: formatPrice(getMonthlyPriceForPlan('Premium'),getCurrencyForPlan('Premium')),
    mobileButtonYear: formatPrice(getYearlyPriceForPlan('Premium'),getCurrencyForPlan('Premium'))
  },{
    title: 'Ultra',
    subTitle: trans('The most advanced plan for big activity'),
    numberOfInvitations: 250,
    inviteOption:true,
    discountOption:'30%',
    tokenOption: trans('Accumulate over month'),
    earlyAccess: true,
    monthlyPrice: formatPrice(getMonthlyPriceForPlan('Ultra'),getCurrencyForPlan('Ultra')),
    annualPrice: formatPrice(getYearlyMonthlyPriceForPlan('Ultra'),getCurrencyForPlan('Ultra')),
    buttonText: trans('Subscribe'),
    mobileButtonMonth: formatPrice(getMonthlyPriceForPlan('Ultra'),getCurrencyForPlan('Ultra')),
    mobileButtonYear: formatPrice(getMonthlyPriceForPlan('Ultra'),getCurrencyForPlan('Ultra'))
  }
]
const headers =[
  trans('Invitations per month'),
  trans('Invite by email, SMS or QR code'),
  trans('Discount on top-up invitations'),
  trans('Unused invitations'),
  trans('Early access to new features') ]
const pricingType= ref('monthly')
const activePlan = ref(0)


// onMounted(() => {
  
//   console.log("pricing",getCurrencyForPlan('Basic'));
//   console.log("pricing",getMonthlyPriceForPlan('Basic'));
//   console.log("pricing",getYearlyPriceForPlan('Basic'));
//   console.log("pricing",getCurrencyForPlan('Premium'));

// })

</script>

<template>

  <div id='pricing' class="flex flex-col mt-20 md:mt-24 items-center mx-auto snap-start w-full">
    <animated-component animation-type="fade-top">
    <p class="text-main-teal text-sm md:text-xl" >{{$t('Pricing')}}</p>
    </animated-component>
      <animated-component animation-type="fade-top">
    <p class="text-white/70 text-2xl md:text-5xl md:mt-4 font-semibold md:font-bold" >{{$t('Choose right plan for you')}}</p>
      </animated-component>
<!--    desktop pricing-->
    <animated-component animation-type="fade">
<div class="md:flex flex-col hidden">

    <div class="mt-6 bg-[#3D3D3D] rounded-[50px] *:rounded-[50px] mx-auto relative">
      <div v-if="pricingType=='annual'" style="top: -12px; right: -11px" class="absolute bg-green-600 text-white text-xs py-1 px-2 rounded-bl-[10px]">-10%</div>
      <button class="px-5 py-3 text-white font-bold text-sm " :class="{['gradient-button']:pricingType === 'monthly'}" @click="pricingType='monthly'">{{$t('Monthly')}}</button>
      <button class="px-5 py-3 text-white font-bold text-sm" :class="{['gradient-button']:pricingType === 'annual'}" @click="pricingType='annual'">{{$t('Yearly')}}</button>
    </div>

    <div class="mt-8 flex flex-col w-full" >
      <div class="grid  grid-cols-6 grid-flow-col relative [&>span]:text-white [&>span]:text-lg lg:[&>span]:text-xl [&>span]:my-4 [&>span]:mx-2 [&>span]:text-center [&>span]:self-center [&>img]:self-center [&>img]:justify-self-center  *:z-[2]">
        <span class="col-span-2 !text-left" :style="{'grid-row-start':index+3}"
              v-for="(header,index) in headers">
          {{ $t(header) }}
        </span>
<!--        decorations-->
        <div v-for="n in 6" class="gradient-underline absolute w-full h-[1px]" :style="{'grid-row-start':n+2}"/>
        <div class="gradient-border-mask !absolute row-start-1 row-end-9 col-start-5 col-end-6 w-full h-full z-[1] rounded-3xl before:rounded-3xl"></div>

        <template v-for="(plans,index) in pricingPlans" >
          <span class='!mb-2 !self-end !text-xl lg:!text-2xl' :style="{'grid-column-start':index+3}">{{plans.title}}</span>
          <span class='!text-sm !self-start !mt-0' :style="{'grid-column-start':index+3}">{{$t(plans.subTitle)}}</span>
          <span :style="{'grid-column-start':index+3}">{{plans.numberOfInvitations}}</span>
          <img :style="{'grid-column-start':index+3}" :src="plans.inviteOption ? includedIcon : notIncludedIcon"/>
          <span v-if="typeof plans.discountOption === 'string'" :style="{'grid-column-start':index+3}">{{$t(plans.discountOption)}}</span>
          <img v-else :style="{'grid-column-start':index+3}" :src="notIncludedIcon"/>
          <span v-if="typeof plans.tokenOption === 'string'" :style="{'grid-column-start':index+3}">{{$t(plans.tokenOption)}}</span>
          <img v-else :style="{'grid-column-start':index+3}" :src="notIncludedIcon"/>
          <img :style="{'grid-column-start':index+3}" :src="plans.earlyAccess ? includedIcon : notIncludedIcon"/>
          <span class="!text-lg lg:!text-2xl" :style="{'grid-column-start':index+3}">{{pricingType === 'monthly' ? plans.monthlyPrice : plans.annualPrice}}<span v-if="pricingType=='annual'">{{$t('/mo.')}}</span></span>

          
          <a href="/r" class='border mx-2 min-h-[calc(100%-16px)]] my-4 text-base text-center lg:text-lg  text-white border-main-blue rounded-[30px] flex items-center justify-center p-4' :class="{['bg-main-blue']:plans.fillButton}" :style="{'grid-column-start':index+3, 'grid-row-end':10}">{{$t(plans.buttonText)}}</a>
        </template>
      </div>
    </div>
</div>
    </animated-component>

<!--    mobile pricing-->
    <animated-component animation-type="fade">
    <div class="flex flex-col md:hidden w-full">
    <div class="flex space-x-1 mt-4 mx-auto">
      <button class='px-4 py-2 text-white rounded-3xl' :class="{['gradient-button']:activePlan === index}" v-for="(plans,index) in pricingPlans" @click="activePlan=index">
        {{plans.title}}
      </button>
    </div>

      <div class="relative w-full grid grid-cols-2 mt-4 [&>span]:text-white [&>span]:text-base [&>span]:my-3 [&>span]:mx-2 [&>span]:text-center [&>span]:self-center [&>img]:self-center [&>img]:justify-self-center">
        <span class="!text-left" :style="{'grid-row-start':index+2}"
              v-for="(header,index) in headers">
          {{ $t(header) }}
        </span>
        <span class='!text-lg col-start-1 col-span-2 row-start-1 !text-left' >{{$t(pricingPlans[activePlan].subTitle)}}</span>
        <span class="col-start-2 row-start-2" >{{pricingPlans[activePlan].numberOfInvitations}}</span>
        <img class="col-start-2 row-start-3"  :src="pricingPlans[activePlan].inviteOption ? includedIcon : notIncludedIcon"/>
        <span class="col-start-2 row-start-4" v-if="typeof pricingPlans[activePlan].discountOption === 'string'" >{{pricingPlans[activePlan].discountOption}}</span>
        <img class="col-start-2 row-start-4" v-else  :src="notIncludedIcon"/>
        <span class="col-start-2 row-start-5" v-if="typeof pricingPlans[activePlan].tokenOption === 'string'" >{{$t(pricingPlans[activePlan].tokenOption)}}</span>
        <img class="col-start-2 row-start-5" v-else  :src="notIncludedIcon"/>
        <img class="col-start-2 row-start-6" :src="pricingPlans[activePlan].earlyAccess ? includedIcon : notIncludedIcon"/>
        <a v-if="activePlan===0" href="/r" class='text-base text-center lg:text-lg border mx-2 my-4 text-white border-main-blue rounded-[30px] flex items-center justify-center p-4 row-start-7 col-span-2 bg-main-blue'>{{$t(pricingPlans[activePlan].buttonText)}}</a>
        <a v-if="activePlan!==0" href="/r" class='text-base text-center lg:text-lg border mx-2 mt-4 text-white border-main-blue rounded-[30px] flex items-center justify-center p-4 row-start-7 col-span-2 '>{{$t(pricingPlans[activePlan].mobileButtonMonth)}} / {{ $t('month') }}</a>
        <a v-if="activePlan!==0" href="/r" class='relative text-base text-center lg:text-lg border mx-2 mt-4 text-white border-main-blue rounded-[30px] flex items-center justify-center p-4 row-start-8 col-span-2 bg-main-blue'>
          {{$t(pricingPlans[activePlan].mobileButtonYear)}} / {{ $t('year') }}
          <small style="top: -10px; right: -10px;" class="absolute bg-white text-main-blue rounded-full px-2 py-1 text-xs">-10%</small>
        </a>




        <div v-for="n in 5" class="gradient-underline absolute w-full h-[1px]" :style="{'grid-row-start':n+1}"/>

      </div>
    </div>
    </animated-component>



  </div>
</template>
