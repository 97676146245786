<script setup>
import step1 from '../../img/howItWorks/step1.png'
import step2 from '../../img/howItWorks/step2.png'
import step3 from '../../img/howItWorks/step3.png'
import step4 from '../../img/howItWorks/step4.png'
import HowItWorksCard from "@/Components/HowItWorksCard.vue";
import {ref} from "vue";
import AnimatedComponent from "@/Components/AnimatedComponent.vue";
const activeStep = ref(1);
const steps = [step1,step2,step3,step4]


</script>

<template>

  <div id='howItWorks' class="flex flex-col w-full mt-20 md:mt-24 items-center mx-auto snap-start">
    <animated-component animation-type="fade-top">
    <p class="text-main-teal text-sm md:text-xl" >{{ $t('Process') }}</p>
    </animated-component>
    <animated-component animation-type="fade-top">
    <p class="text-white/70 text-2xl md:text-5xl md:mt-4 font-semibold md:font-bold" >{{ $t('HOW IT WORKS?') }}</p>
    </animated-component>

    <animated-component animation-type="fade">
    <div class="flex md:hidden flex-col mt-8 space-y-6" >
      <HowItWorksCard :image-url="step1" :step-title="$t('Step 1')" :title="$t('Invite the patient')" :text="$t('The medical provider invites the patient via previsit.ai website or his own  software (e.g., EMR, HIS, Online agenda) sends an invitation for a patient, before an upcoming medical visit.')"/>
      <HowItWorksCard :image-url="step2" :step-title="$t('Step 2')" :title="$t('Patient notification')" :text="$t('The patient receives a notification through SMS, email, or another method, containing a URL to complete the previsit information.')"/>
      <HowItWorksCard :image-url="step3" :step-title="$t('Step 3')" :title="$t('Patient talks with AI')" :text="$t('The patient interacts with the assistant via a mobile or desktop browser, providing necessary information.')"/>
      <HowItWorksCard :image-url="step4" :step-title="$t('Step 4')" :title="$t('Summary generation and integration')" :text="$t('Previsit.ai compiles the information into a medical-grade summary, which is then integrated into the doctor\'s workflow directly or via 3rd party software.')"/>

    </div>
    </animated-component>
    <animated-component animation-type="fade">
    <div class="hidden md:flex w-full mt-24">
      <div class="flex-[5_5_0%] flex flex-col ml-12">
        <div :class="activeStep === 1 ? 'active-circle' : 'circle'" class=" line cursor-pointer" @click="activeStep=1">
          <p class="text-main-teal text-2xl opacity-70" :class="{ 'text-[32px] !opacity-100': activeStep === 1 } "> 
            {{ $t("Invite the patient") }}
          </p>
          <p class="text-white text-base hidden mt-4 mb-12"  :class="{ '!flex': activeStep === 1 }">{{  $t('The medical provider invites the patient via previsit.ai website or automatically, via his own  software (e.g., EMR, HIS, Online agenda) to perform a previsit before an upcoming medical appointment.') }}</p>
        </div>
        <div :class="activeStep === 2 ? 'active-circle' : 'circle'" class=" line cursor-pointer" @click="activeStep=2">
          <p class="text-main-teal text-2xl opacity-70 mt-10" :class="{ 'text-[32px] !opacity-100': activeStep === 2 }">{{ $t('Patient notification') }}</p>
         <p class="text-white text-base hidden mt-4 mb-12"  :class="{ '!flex': activeStep === 2 }">{{ $t('The patient receives a notification through SMS, email, or another method, containing a URL to complete the previsit information.') }}</p>
        </div>
        <div :class="activeStep === 3 ? 'active-circle' : 'circle'" class=" line cursor-pointer" @click="activeStep=3">
          <p class="text-main-teal text-2xl opacity-70 mt-10" :class="{ 'text-[32px] !opacity-100': activeStep === 3 }"> {{ $t('Patient talks with AI') }}</p>
         <p class="text-white text-base hidden mt-4 mb-12"  :class="{ '!flex': activeStep === 3 }">{{ $t('The patient interacts with assistant via a mobile or desktop browser, providing necessary information.') }}</p>
        </div>
        <div :class="activeStep === 4 ? 'active-circle' : 'circle'" class=" line cursor-pointer" @click="activeStep=4">
          <p class="text-main-teal text-2xl opacity-70 mt-10" :class="{ 'text-[32px] !opacity-100': activeStep === 4 }"> {{ $t('Summary generation and integration') }}</p>
         <p class="text-white text-base hidden mt-4"  :class="{ '!flex': activeStep === 4 }">{{ $t('Previsit.ai compiles the information into a medical-grade summary, which is then integrated into the doctor\'s workflow directly or via 3rd party software.') }}</p>
        </div>

      </div>
      <div class="flex flex-[7_7_0%] ml-8">
        <img class='object-contain' :src="steps[activeStep-1]" alt="screenshot"/>
      </div>


    </div>
    </animated-component>
  </div>
</template>

<style scoped>
.activeTitle {

}
</style>
