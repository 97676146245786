<script setup>
import logo from '../../img/logo-name.svg'
import NavigationLink from "@/Components/NavigationLink.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {useForm} from "@inertiajs/vue3";
import {useReCaptcha} from "vue-recaptcha-v3";
import {nextTick} from "vue";
import ContactForm from "@/Components/ContactForm.vue";

const form = useForm({
  email: '',
  captcha_token: null
})
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const recaptcha = async () => {
  console.log("recaptcha");
  await recaptchaLoaded();
  form.captcha_token = await executeRecaptcha("login");
  submit();
};

const scrollTo = (name) => {
  nextTick(() => {
    const element = document.getElementById(name);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  });
};
const submit = async () => {
    console.log("submitting");
    const response = await fetch(route("request-demo"), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(form),
    });

    if (response.ok) {
        window.location.href = "/demo";
    } else {
        console.error('Error:', response.status, response.statusText);
    }

    // Rest of your code
};


</script>

<template>

  <div class="hidden px-24 py-16 bg-black grid-cols-3 md:grid w-full mt-24 items-center mx-auto space-x-10">
    
    <a href="/">
      <img class="h-12 my-auto flex-1" :src="logo" alt="logo"/>
    </a>
    <ul class="flex flex-col flex-1 space-y-6">
      <NavigationLink href="#features" @click="scrollTo('features')">{{ $t('Features') }}</NavigationLink>
      <NavigationLink href="#features" @click="scrollTo('ourOffer')">{{ $t('Benefits') }}</NavigationLink>
      <NavigationLink href="#features" @click="scrollTo('howItWorks')">{{ $t('Previsit') }}</NavigationLink>
       <NavigationLink href="#features" @click="scrollTo('pricing')">{{ $t('Pricing') }}</NavigationLink>
      <NavigationLink href="#features" @click="scrollTo('aboutUs')">{{ $t('About us') }}</NavigationLink>
    </ul>
    <div class="p-6 rounded-md shadow-lg relative w-full max-w-md mx-auto">
      <ContactForm :title="$t('Contact us')" :appearance="'button'"/>
<!--      <p class="text-main-white text-lg">{{$t('Request Demo')}}</p>-->
<!--      <p class="text-base text-white mt-2">{{ $t('Please provide your email in order to access the demo app.') }}</p>-->
<!--      <form @submit.prevent="recaptcha" class="space-y-4">-->
<!--        <meta name="csrf-token" content="{{ csrf_token() }}">-->
<!--        <div class="text-left">-->
<!--          <label for="email" class="block text-main-white text-base">{{ $t('Email:') }}</label>-->
<!--          <input type="email" id="email" v-model="form.email" required class="focus:outline-none mt-2 block w-full rounded-md bg-[#666666] border-transparent text-white"/>-->
<!--        </div>-->
<!--        <button class="mt-4 text-right bg-main-blue text-white px-4 py-2 rounded-[36px]">-->
<!--          {{ $t('Request Demo') }}-->
<!--        </button>-->
<!--      </form>-->
    </div>
  </div>
</template>
