<template>
  <div>
    <transition name="fade">
      <div>
        <!--         mobile view-->
        <div v-if="showModal"
             class="flex xl:hidden gradient-bg fixed w-screen h-screen inset-0 z-50 flex-col py-8 px-5">
          <div class="relative flex flex-col">
            <div @click="showModal = false" class="absolute flex">
              <img :src="chevronLeftIcon" alt="back-icon"/>
              <span class="text-sm text-white ml-2">Go back</span>
            </div>
            <img class='mx-auto h-6' :src="logo" alt="logo"/>
            <p class="text-left text-white text-lg mt-16">
              {{ $t('Please provide your email in order to access the demo app.') }}</p>
            <form @submit.prevent="recaptcha" class="mt-6 flex flex-col">
              <meta name="csrf-token" content="{{ csrf_token() }}">
              <div class="text-left">
                <label for="email" class="block relative text-white">{{ $t('Email address') }}
                  <img class='absolute left-4 top-[46px]' :src="mailIcon" alt='mail icon'/>
                  <input type="email" id="email" v-model="form.email" placeholder="name@gmail.com" required
                         class="mt-1 block bg-transparent w-full rounded-xl border-[#7C7882] placeholder-[#96939B] py-3 pl-12">
                </label>
              </div>
              <PrimaryButton class="w-full px-10 py-3 bg-main-blue mt-6 ml-auto items-center justify-center rounded-xl "
                             :disabled="formLoading" :class="{ 'opacity-25': formLoading }">
                <svg v-if="formLoading" class="text-gray-300 animate-spin mx-10" viewBox="0 0 64 64" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     width="24" height="20">
                  <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"
                      class="text-gray-900">
                  </path>
                </svg>
                <span v-else class="text-white text-base">{{ $t('Request Demo') }}</span>
              </PrimaryButton>
            </form>
          </div>
        </div>
        <!--         desktop view-->
        <div v-if="showModal" class="hidden fixed inset-0 xl:flex items-center justify-center z-50">
          <div class="fixed inset-0 bg-black opacity-50" @click="showModal = false"></div>
          <div
              class="flex flex-col bg-[#1A1A1A] p-6 rounded-md before:rounded-md shadow-lg shadow-white/10 relative w-full max-w-md mx-auto gradient-border-demo">
            <button @click="showModal = false"><img class='ml-auto' :src="closeIcon" alt='close'/></button>
            <p class="text-left text-white text-lg mt-1">
              {{ $t('Please provide your email in order to access the demo app.') }}</p>
            <form @submit.prevent="recaptcha" class="mt-6 flex flex-col">
              <meta name="csrf-token" content="{{ csrf_token() }}">
              <div class="text-left">
                <label for="email" class="block relative text-white">{{ $t('Email address') }}
                  <img class='absolute left-4 top-[50px]' :src="mailIcon" alt='mail icon'/>
                  <input type="email" id="email" v-model="form.email" placeholder="name@gmail.com" required
                         class="mt-1 block bg-[#1A1A1A] w-full rounded-md border-[#7C7882] placeholder-[#96939B] py-4 pl-12">
                </label>
              </div>
              <PrimaryButton class="px-10 py-3 bg-main-blue mt-6 text-white ml-auto text-base" :disabled="formLoading"
                             :class="{ 'opacity-25': formLoading }">
                <svg v-if="formLoading" class="text-gray-300 animate-spin mx-10" viewBox="0 0 64 64" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     width="24" height="20">
                  <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"
                      class="text-gray-900">
                  </path>
                </svg>
                <span v-else>{{ $t('Request Demo') }}</span>
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="appearance === 'primary-button'" class="flex " id="try-it-out">
      <button @click="showModal=true" class="bg-main-blue rounded-xl py-2 px-20 text-main-white text-lg">
        {{ $t(title) }}
      </button>
    </div>
    <div v-else-if="appearance === 'button'" class="flex justify-right md:justify-center" id="try-it-out">
      <button @click="showModal=true" class="border border-main-blue rounded-lg p-2 text-main-white xl:text-lg">
        {{ $t(title) }}
      </button>
    </div>
    <div v-else-if="appearance === 'menuMobile'" class="flex justify-left" id="try-it-out">
       <span @click="showModal=true" class="text-main-teal text-base">
         {{ $t(title) }}
       </span>
    </div>
    <div v-else class="flex justify-right md:justify-center" id="try-it-out">
      <button @click="showModal=true"
              class="md:inline-flex md:items-center md:px-3 md:py-2 md:border md:border-transparent md:text-sm md:leading-4 md:font-medium md:rounded-md md:text-gray-500 md:bg-white md:hover:text-gray-700 md:focus:outline-none md:transition md:ease-in-out md:duration-150">
        {{ $t(title) }}
      </button>
    </div>

  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useForm, usePage} from "@inertiajs/vue3";
import {useReCaptcha} from "vue-recaptcha-v3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import closeIcon from "../../img/icons/closeIcon.svg"
import mailIcon from "../../img/icons/mailIcon.svg"
import chevronLeftIcon from "../../img/icons/chevronLeftIcon.svg"
import logo from '../../img/logo-name.svg'

const showModal = ref(false);
const formLoading = ref(false);

function openModal() {

  showModal.value = true;
}

const form = useForm({
  email: '',
  captcha_token: null
});
const page = usePage();
const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
const recaptcha = async () => {
  // console.log("recaptcha");
  formLoading.value = true
  await recaptchaLoaded();
  form.captcha_token = await executeRecaptcha("login");
  submit();
};

const props = defineProps({
  title: {
    type: String,
    default: 'Demo'
  },
  appearance: {
    type: String,
    default: 'default'
  }

});

defineExpose({openModal})

// const submit = () => {
//     console.log("submitting");
//   form.post(route("request-demo"), {
//     onSuccess: (data) => {
//         form.email = '';
//         form.captcha_token = null;
//         showModal.value = false;
//         console.log("success", data);
//     },
//     onFinish: () => form.reset("password", "password_confirmation"),
//   });
// };


const submit = async () => {
  console.log("submitting");
  const response = await fetch(route("request-demo"), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    body: JSON.stringify(form),
  });


  if (response.ok) {
    formLoading.value = false;
    window.location.href = "/demo";
  } else {
    console.error('Error:', response.status, response.statusText);
  }

  // Rest of your code
};

const submitForm = () => {
  // Here you can send a POST request to your server with the form data
  // You can use the fetch API or a library like axios
};
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 1.0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
