<script setup>
import { ref, onMounted, watch } from 'vue';

const videoRef = ref(null);
const isPlaying = ref(false);
const currentTime = ref(0);
const duration = ref(0);
const volume = ref(1);
const isFullscreen = ref(false);

const togglePlay = () => {
  if (videoRef.value) {
    if (videoRef.value.paused) {
      videoRef.value.play();
    } else {
      videoRef.value.pause();
    }
    isPlaying.value = !videoRef.value.paused;
  }
};

const updateProgress = () => {
  if (videoRef.value) {
    currentTime.value = videoRef.value.currentTime;
    duration.value = videoRef.value.duration;
  }
};

const setProgress = (event) => {
  const progressBar = event.target;
  const clickPosition = event.offsetX / progressBar.offsetWidth;
  videoRef.value.currentTime = clickPosition * videoRef.value.duration;
};

const setVolume = (event) => {
  volume.value = parseFloat(event.target.value);
  if (videoRef.value) {
    videoRef.value.volume = volume.value;
  }
};

const toggleFullscreen = () => {
  if (!document.fullscreenElement) {
    videoRef.value.requestFullscreen().catch(err => {
      console.error(`Error attempting to enable fullscreen: ${err.message}`);
    });
  } else {
    document.exitFullscreen();
  }
};

const rewindVideo = () => {
  if (videoRef.value) {
    videoRef.value.currentTime = 0;
    isPlaying.value = false;
  }
};

onMounted(() => {
  if (videoRef.value) {
    videoRef.value.addEventListener('timeupdate', updateProgress);
    videoRef.value.addEventListener('ended', rewindVideo);
  }
  
  document.addEventListener('fullscreenchange', () => {
    isFullscreen.value = !!document.fullscreenElement;
  });
});

watch(volume, (newVolume) => {
  if (videoRef.value) {
    videoRef.value.volume = newVolume;
  }
});
</script>

<template>
  <div class="video-container my-8 w-full mx-auto relative">
    <video
      ref="videoRef"
      class="w-full h-auto aspect-video"
    >
      <source src="/videos/intro.mp4" type="video/mp4">
      <source src="/videos/intro.webm" type="video/webm">
      Your browser does not support the video tag.
    </video>
    
    <!-- New overlay -->
    <div 
      @click="togglePlay"
      class="absolute inset-0 flex items-center justify-center cursor-pointer"
      :class="{ 'opacity-0': isPlaying }"
    >
      <div class="bg-black bg-opacity-50 rounded-full p-4">
        <svg class="w-16 h-16 text-white" fill="currentColor" viewBox="0 0 20 20">
          <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
        </svg>
      </div>
    </div>
    
    <div class="controls absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-2">
      <div class="flex items-center">
        <button @click="togglePlay" class="text-white mr-2">
          <svg v-if="!isPlaying" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
          </svg>
          <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg>
        </button>
        
        <div class="progress-bar w-full bg-gray-200 h-1 cursor-pointer" @click="setProgress">
          <div class="bg-blue-500 h-full" :style="{ width: `${(currentTime / duration) * 100}%` }"></div>
        </div>
        
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          v-model="volume"
          @input="setVolume"
          class="ml-2 w-20"
        >
        
        <button @click="toggleFullscreen" class="text-white ml-2">
          <svg v-if="!isFullscreen" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" />
          </svg>
          <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path d="M3 3a1 1 0 00-1 1v4a1 1 0 102 0V5h3a1 1 0 000-2H3zm0 10a1 1 0 100 2h4a1 1 0 000-2H3zm9-10a1 1 0 100 2h3v3a1 1 0 102 0V4a1 1 0 00-1-1h-4zm0 14a1 1 0 100-2h3v-3a1 1 0 102 0v4a1 1 0 00-1 1h-4z" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.video-container {
  @apply shadow-lg rounded-lg overflow-hidden;
}

.controls {
  @apply opacity-0 transition-opacity duration-300;
}

.video-container:hover .controls {
  @apply opacity-100;
}

/* New styles for the overlay */
.video-container > div:nth-child(2) {
  @apply transition-opacity duration-300;
}
</style>