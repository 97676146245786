<script setup>

import closeMenuIcon from "../../img/icons/closemenuIcon.svg";
import DropdownLanguage from "@/Components/DropdownLanguage.vue";
import RequestDemo from "@/Components/RequestDemo.vue";
import ContactForm from "@/Components/ContactForm.vue";

const props =defineProps({
  closeMenu: {
    type:Function,
    required:true,
  },
  scrollTo: {
    type:Function,
    required:true,
  },
  isMenuOpen:{
    type: Boolean,
    required:true,
  }

});

const closeAndScroll=(name)=>{
  props.closeMenu()
  props.scrollTo(name)
}

</script>

<template>
    <div v-if="isMenuOpen" class="xl:hidden fixed inset-0 flex z-50">
      <div class="fixed inset-0 bg-black opacity-50" @click="closeMenu()"></div>
      <div class="flex flex-col menu-mobile-bg min-h-screen px-6 py-8 relative w-4/6 overflow-x-scroll">
        <button @click="closeMenu()"><img class='' :src="closeMenuIcon" alt='close'/></button>
        <ul class="flex flex-col space-y-10 mt-8 h-full">
          <li @click="closeAndScroll('features')" class="text-white text-lg ">{{$t('Features')}}</li>
          <li @click="closeAndScroll('ourOffer')" class="text-white text-lg ">{{$t('Benefits')}}</li>
          <li @click="closeAndScroll('howItWorks')" class="text-white text-lg ">{{$t('Previsit')}}</li>
           <li @click="closeAndScroll('pricing')" class="text-white text-lg ">{{$t('Pricing')}}</li>
          <li @click="closeAndScroll('aboutUs')" class="text-white text-lg ">{{$t('About us')}}</li>
          <ContactForm :title="$t('Contact us')"/>
          <DropdownLanguage />
          <RequestDemo class="!mt-auto" :title="$t('Request Demo')" :appearance="'menuMobile'"/>
          <a href="/login" class="text-main-teal text-base !mt-6">{{ $t('Log in') }}</a>
        </ul>

      </div>
    </div>

</template>
