<script setup lang="ts">
import { onMounted, ref } from 'vue';

withDefaults(defineProps<{ animationType?: string, inherit?: boolean }>(), { animationType: 'fade', inherit:false });

const target = ref<Element>();
const animate = ref<boolean>(false);

const observer = new IntersectionObserver(
    ([entry]) => {
      if(!animate.value) {
        animate.value = entry.isIntersecting;
      }
    },
    {
      threshold: 0.1
    }
);

onMounted(() => {
  observer.observe(target.value as Element);
});
</script>

<template>
    <transition ref="target" :name="animationType" v-appear="animate" class="animated-component">
        <slot />
    </transition>
</template>

<style scoped>
.animated-component.fade-enter-from,
.animated-component.fade-top-enter-from,
.animated-component.fade-left-enter-from,
.animated-component.fade-left-delay-enter-from,
.animated-component.fade-left-delay2-enter-from,
.animated-component.fade-right-enter-from,
.animated-component.fade-right-delay-enter-from,
.animated-component.zoom-enter-from {
  transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 700ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
  transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
  transform: scale(0.9);
}

.fade-top-enter-active, .fade-top-leave-active {
  transition: all 700ms ease;
  transform-origin: top;
}
.fade-top-enter-from, .fade-top-leave-to {
  opacity: 0;
  transform: translateY(-40px);
}
.fade-top-enter-to, .fade-top-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-left-enter-active, .fade-left-leave-active {
  transition: all 700ms ease;
  transform-origin: left;
}
.fade-left-enter-from, .fade-left-leave-to {
  opacity: 0;
  transform: translateX(-40px);
}
.fade-left-enter-to, .fade-left-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.fade-left-delay-enter-active, .fade-left-delay-leave-active {
  transition: all 500ms ease 600ms;
  transform-origin: left;
}
.fade-left-delay-enter-from, .fade-left-delay-leave-to {
  opacity: 0;
  transform: translateX(-40px);
}
.fade-left-delay-enter-to, .fade-left-delay-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.fade-left-delay2-enter-active, .fade-left-delay2-leave-active {
  transition: all 500ms ease 1800ms;
  transform-origin: left;
}
.fade-left-delay2-enter-from, .fade-left-delay2-leave-to {
  opacity: 0;
  transform: translateX(-40px);
}
.fade-left-delay2-enter-to, .fade-left-delay2-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.fade-right-enter-active, .fade-right-leave-active {
  transition: all 700ms ease;
  transform-origin: right;
}
.fade-right-enter-from, .fade-right-leave-to {
  opacity: 0;
  transform: translateX(40px);
}
.fade-right-enter-to, .fade-right-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.fade-right-delay-enter-active, .fade-right-delay-leave-active {
  transition: all 500ms ease 1200ms;
  transform-origin: right;
}
.fade-right-delay-enter-from, .fade-right-delay-leave-to {
  opacity: 0;
  transform: translateX(40px);
}
.fade-right-delay-enter-to, .fade-right-delay-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
