<script setup>
import avatarMichal from '../../img/aboutUs/avatarMichal.png'
import avatarDamian from '../../img/aboutUs/avatarDamian.png'
import avatarJakub from '../../img/aboutUs/avatarJakub.png'
import linkedInIcon from '../../img/aboutUs/linkedin-app-white-icon.png'
import AnimatedComponent from "@/Components/AnimatedComponent.vue";

const openLink = (link) => {
  window.open(link, '_blank');
}

</script>

<template>

  <div id="aboutUs" class="flex flex-col w-full mt-20 md:mt-24 items-center mx-auto snap-start">
    <animated-component animation-type="fade-top" >
      <p class="text-main-teal text-sm md:text-xl">{{$t('About us')}}</p>
    </animated-component>
    <animated-component animation-type="fade-top" >
      <p class="text-white/70 text-2xl md:text-5xl md:mt-4 font-semibold md:font-bold" >{{$t('GET TO KNOW US')}}</p>
    </animated-component>


    <div class="mt-8 flex flex-col gap-6 md:mx-24">
      <div class="flex flex-row">
        <animated-component inherit animation-type="fade-left" >
          <img :src="avatarMichal" class="size-24 md:size-40 mr-8 cursor-pointer" @click="openLink('https://linkedin.com/in/michał-nedoszytko-md-phd-9243748')"/>
        </animated-component>
        <animated-component animation-type="fade-right" >
        <div class="flex flex-col justify-center " >
          <p class="text-white text-base md:text-xl font-semibold">Michał Nedoszytko, MD PhD  <img :src="linkedInIcon" class="cursor-pointer mb-1 ml-3 size-4 md:size-6 inline opacity-90" @click="openLink('https://linkedin.com/in/michał-nedoszytko-md-phd-9243748')"/></p>
          <p class="text-white text-sm md:text-lg mt-4 inline">{{$t('Cardiologist. Software developer. Author and originator of the project. Works and lives in Belgium. AI enthusiast and evangelist.')}}  
            
           </p>
         
        </div>
        </animated-component>
      </div>
      <div class="flex flex-row">
        <animated-component inherit animation-type="fade-left" >
        <img :src="avatarDamian" class="size-24 md:size-40 mr-8" />
        </animated-component>
        <animated-component animation-type="fade-right" >
        <div class="flex flex-col justify-center"  >
          <p class="text-white text-base md:text-xl font-semibold">Damian Drela, MD</p>
          <p class="text-white text-sm md:text-lg mt-4">{{$t('Internal medicine specialist. Doctor, programmer, fan of LEGO and Star Wars.')}}</p>
        </div>
        </animated-component>
      </div>
      <div class="flex flex-row">
        <animated-component inherit animation-type="fade-left" >
        <img :src="avatarJakub" class="size-24 md:size-40 mr-8" />
        </animated-component>
        <animated-component animation-type="fade-right" >
        <div class="flex flex-col justify-center " >
          <p class="text-white text-base md:text-xl font-semibold">Jakub Łukasik</p>
          <p class="text-white text-sm md:text-lg mt-4">{{$t('Developer and IT systems administrator. Drone and photography enthusiast. Founder of devcube.pl.')}}</p>
        </div>
        </animated-component>
      </div>

    </div>


  </div>
</template>
