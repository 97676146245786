<script setup>
import doctorAnytimeLogo from '../../img/doctor-anytime-logo.svg'
import docplannerLogo from '../../img/docplanner-logo.svg'
import AnimatedComponent from "@/Components/AnimatedComponent.vue";
const openLink = (url) => {
  window.open(url, '_blank');
}

</script>
<template>
  <div class="mx-auto flex flex-col mt-12 md:mt-12 items-center snap-start">
    <animated-component animation-type="fade-top">
      <p class="text-main-teal text-sm md:text-xl" >{{ $t('Our partners') }}</p>
    </animated-component>
    <div class="flex space-x-4 md:space-x-8 mt-4">
      <animated-component animation-type="fade-left">
      <div class="gradient-border-mask py-4 px-6 md:px-8 md:py-6 rounded-3xl before:rounded-3xl cursor-pointer" @click="openLink('https://docplanner.com')"><img :src="docplannerLogo" alt="Docplanner logo"/></div>
      </animated-component>
      <animated-component animation-type="fade-right">
      <div class="gradient-border-mask p-6 md:p-8 rounded-3xl before:rounded-3xl cursor-pointer" @click="openLink('https://doctoranytime-global.com')"><img :src="doctorAnytimeLogo" alt="Doctor Anytime logo"/></div>
      </animated-component>
    </div>


  </div>
</template>
